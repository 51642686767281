import React from "react";
import Header from "../parts/Header";
import Grid from "@mui/material/Grid";
import AddToDiscordButton from "../parts/AddToDiscordButton";
import DescriptionRight from "../parts/DescriptionRight";
import DescriptionLeft from "../parts/DescriptionLeft";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AddToDiscord from "../parts/AddToDiscord";

const Homepage: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>読みそ - トップページ</title>
      </Helmet>
      <Header />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <p>TODO: 読みその画像</p>
        <h1>読みそ</h1>
        <h3>Discordチャット読み上げBot</h3>
        <AddToDiscordButton />
      </Grid>
      <DescriptionRight
        image_url="https://test.com"
        desc_name="desc1"
      />
      <DescriptionLeft
        image_url="https://test.com"
        desc_name="desc2"
      />
      <DescriptionRight
        image_url="https://test.com"
        desc_name="desc3"
      />
      <DescriptionLeft
        image_url="https://test.com"
        desc_name="desc4"
      />
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={3}>
          <AddToDiscord />
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        (c) 2023 Megumiso
      </Grid>
    </HelmetProvider>
  )
};

export default Homepage
