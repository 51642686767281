import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddToDiscordButton from "./AddToDiscordButton";
import Grid from "@mui/material/Grid";

const AddToDiscord: React.FC = () => {
  return (
    <Card variant="outlined">
      <Grid container alignItems="center" justifyContent="center">
      <CardContent>
        読みそを追加してみよう！
      </CardContent>
      <CardContent>
        <AddToDiscordButton />
      </CardContent>
      </Grid>
    </Card>
  );
}

export default AddToDiscord
