import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";

import Homepage from "./components/pages/Homepage"
import logo from './logo.svg';
import './App.css';


const App: React.FC = () => {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
        </Routes>
      </BrowserRouter>
  );
};

export default App;
