// 左に画像が来るパターン

import React from "react";
import ReactMarkdown from "react-markdown";
import desc1 from '../../texts/desc1';
import desc2 from '../../texts/desc2';
import desc3 from '../../texts/desc3';
import desc4 from '../../texts/desc4';
import Grid from "@mui/material/Grid";

const DescriptionLeft: React.FC<{ image_url: string, desc_name: string;}> = (props) => {
  let text: string = "";
  if (props.desc_name === "desc1") {
    text = desc1
  } else if (props.desc_name === "desc2") {
    text = desc2
  } else if (props.desc_name === "desc3") {
    text = desc3
  } else if (props.desc_name === "desc4") {
    text = desc4
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs />
        <Grid item xs={6}>
          <ReactMarkdown>
            {text}
          </ReactMarkdown>
        </Grid>
        <Grid item xs={4}>
          画像: {props.image_url}
        </Grid>
        <Grid item xs />
      </Grid>
    </div>
    
  );
}

export default DescriptionLeft
