import React from "react";
import Button from "@mui/material/Button";

const AddToDiscordButton: React.FC = () => {
  return (
    <Button variant="contained">
      ｱｲｺﾝ
      サーバーへ追加
    </Button>
  );
}

export default AddToDiscordButton
